import React from 'react';
import HeaderComponent from '../component/HeaderComponent.jsx';

function About() {
    return (<>
        <HeaderComponent pageName='{À propos}'/>
        </>);
           
}

export default About;


