import axios from 'axios';
import env from '../env/env.json';


function PublishEvent(filename, formData) {
    const APIEndPoint = env.publicApi + 'event/'
    const fixData = filterData(formData)
    const publish = async (filename, fixData) => {
        //event.preventDefault();

        try {
          //console.log("Call : " + APIEndPoint + filename)
          const response = await axios.put(APIEndPoint + filename, fixData ,
            {headers: {
              "X-API-Key": env.publicKey,
              "ClientKey": "bb3893e1-be40-4db9-ba11-cee8038066fa",
                }
            });
          //console.log('Response:', response.data);
          return response
        } catch (error) {
          console.error('Erreur:', error);
          return error
        }
    };
    return publish(filename, fixData)
}


function filterData(formData) {
  var newData = new Map();
  Object.keys(formData).map(key => {
    var value = formData[key]
    if (value !== null && value !== undefined && (typeof value === 'string' || value instanceof String)) {
      //A key to just trim
      value = value.trim()
    }
    newData[key] = value
})
return newData


}

export default PublishEvent