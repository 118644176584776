import React from 'react';
import HeaderComponent from '../component/HeaderComponent.jsx';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../css/Common.css";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import vetImage from '../vet.jpg'; // with import

function Home() {
    return (<>
            <HeaderComponent pageName='La santé de votre animal de compagnie est à portée de clic!'/>

            <div className="sd-root-modern"  >
                <div className='sv-components-row'>
                    <div className="sd-body sd-body--static" >
                        <div className="sd-page">
                           
                        <h4 className="sd-title sd-page__title"><span className="sv-string-viewer">À propos</span></h4>
                        
                        <p>
                        
                        <label className="label-small" >
                            <img src={vetImage} className="Float-image"/>
                            Dre Cindy Lefebvre pratique dans la région de Québec depuis l&#39;obtention de son doctorat en médecine vétérinaire en 2013. 
                            Elle a d&#39;abord travaillé en centre d&#39;urgence avant de débuter la médecine familiale quelques années plus tard. 
                            Elle a établi en 2020 un service de télémédecine vétérinaire et y consacre la majorité de ses heures de travail depuis, 
                            en plus de pratiquer en médecine d&#39;urgence. Elle est empathique et très à l&#39;écoute des propriétaires et de leurs besoins.
                            
                        </label>
                        </p>
                        
                        <h4 className="sd-title sd-page__title"><span className="sv-string-viewer">Quel est le fonctionnement?</span></h4>
                        <p>
                            <label className="label-small" >
                            Prenez le bien-être de votre animal en main dès aujourd&#39;hui en rejoignant notre plateforme de télémédecine vétérinaire.
                            Inscrivez-vous facilement, prenez rendez-vous et offrez à votre compagnon à quatre pattes les soins qu&#39;il mérite. 
                            Chez nous, la santé de votre animal est notre priorité absolue.
                            <p/>
                            Que ce soit pour des questions générales sur sa santé, des soucis de comportement, pour faciliter son transport en voiture ou un problème 
                            de santé mineur, nous pouvons vous aider. 
                            <p/>
                            Dans certains cas, une consultation en personne demeure essentielle. Nous pouvons vous aider à déterminer s&#39;il est préférable  de vous diriger vers un centre d&#39;urgence, 
                            ou mettre en place des soins qui aideront votre animal en attendant l&#39;ouverture de votre clinique vétérinaire habituelle. 
                
                            <br/>
                            
                            <p/>
                            <b>*</b>Prenez note que des frais de consultation vétérinaire sont applicable, et devront être acquités par virement interact
                            <p/>
                            Fini les tracas et les déplacements stressants pour une simple consultation.
                            </label>
                            
                        </p>
                        <label className="label-small" >
                            <br/> Besoin de plus d&#39;information? Contactez-nous à <a href="mailto:info@cindyvet.com">info@cindyvet.com</a>
                        </label>
                        </div>
                        
                        <Button class="sd-btn"><Link class="nav-link" to="/reservation">Prendre rendez-vous</Link></Button>
                        <br/>
                    </div>
                </div>
                
            </div>
        </>);
           
}

export default Home;


